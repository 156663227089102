import Article from "../components/Article/";
import Breadcrumbs from "../components/Article//Breadcrumbs";
import excerptHtml from "excerpt-html";
import Headline from "../components/Article/Headline";
import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import Seo from "../components/Seo";
import { FaCalendar } from "react-icons/fa/";
import { FaClock } from "react-icons/fa/";
import { FaTag } from "react-icons/fa/";
import { graphql, Link } from "gatsby";
import { ThemeContext } from "../layouts";

const ArticlePage = props => {
  const {
    data: {
      posts: { edges: posts }
    }
  } = props;

  return (
    <React.Fragment>
      <ThemeContext.Consumer>
        {theme => (
          <Article theme={theme}>
            <Breadcrumbs slug="/articles" title={""} theme={theme} />
            <Headline title="EconTechie - Articles" theme={theme} />
            {posts.map(post => {
              const {
                node: {
                  fields: { slug, prefix },
                  timeToRead,
                  frontmatter: { title, intro, category }
                }
              } = post;
              return (
                <div key={slug}>
                  <Link to={slug} className="link">
                    <h4> {title} </h4>
                  </Link>
                  <div className="meta">
                    <span>
                      <FaCalendar size={18} />
                      <div title={moment(prefix).format("MMMM D, YYYY")}>
                        {moment(prefix).fromNow()}
                      </div>
                    </span>
                    <span>
                      <FaClock size={18} />
                      <div> {timeToRead + " minutes"} </div>
                    </span>
                    <Link
                      to={`/category/${category
                        .toLowerCase()
                        .split(" ")
                        .join("-")}`}
                    >
                      {category && (
                        <span>
                          <FaTag size={18} />
                          <div> {category} </div>
                        </span>
                      )}
                    </Link>
                  </div>
                  <p>{excerptHtml(intro)}</p>
                  <br />
                </div>
              );
            })}
            <style jsx>{`
              :global(.p) {
                width: 100%;
                color: ${theme.text.color.primary};
              }

              li {
                margin: ${`calc(${theme.space.default} * 2) 0 calc(${theme.space.default} * 3)`};
                padding: ${theme.space.default} ${theme.space.inset.s};
                position: relative;
                transition: all ${theme.time.duration.default};
                background-color: #1f262e;
                color: ${theme.text.color.primary};

                &::after {
                  border-top: 1px solid ${theme.line.color};
                  content: "";
                  height: 0;
                  position: absolute;
                  bottom: ${`calc(${theme.space.default} * -1)`};
                  left: 50%;
                  transform: translateX(-50%);
                  transition: all ${theme.time.duration.default};
                  width: 50%;
                }

                &:first-child {
                  &::before {
                    border-top: 1px solid ${theme.line.color};
                    content: "";
                    height: 0;
                    position: absolute;
                    top: ${`calc(${theme.space.default} * -1.5)`};
                    left: 50%;
                    transform: translateX(-50%);
                    transition: all ${theme.time.duration.default};
                    width: 50%;
                  }
                }
              }

              h4 {
                text-remove-gap: both;
              }

              .meta {
                display: flex;
                align-items: left;
                flex-flow: row wrap;
                font-size: 0.7em;
                padding: ${theme.space.s} ${theme.space.s};
                background: transparent;

                :global(svg) {
                  fill: ${theme.icon.color};
                  margin: ${theme.space.inline.xs};
                }
                span {
                  align-items: left;
                  display: flex;
                  text-transform: uppercase;
                  margin: ${theme.space.s} ${theme.space.s} ${theme.space.s} 0;
                }

                :global(a) {
                  font-weight: ${theme.font.weight.bold};
                  color: ${theme.color.brand.primary};
                  text-decoration: underline;
                }
              }

              p {
                line-height: 1.5;
                font-size: 0.9em;
                padding: 0 ${theme.space.s};
                text-remove-gap: both;
              }

              @from-width tablet {
                li {
                  padding: ${theme.space.default};

                  &::after {
                    bottom: ${`calc(${theme.space.default} * -2)`};
                  }

                  &:first-child {
                    &::before {
                      top: ${`calc(${theme.space.default} * -1.75)`};
                    }
                  }
                }

                h2 {
                  font-size: ${`calc(${theme.blog.h1.size}`};
                  transition: all 0.5s;
                }
              }
              @from-width desktop {
                li {
                  padding: 0 0 ${`calc(${theme.space.default} * 2)`};

                  &::after {
                    bottom: ${`calc(${theme.space.default} * -1.5)`};
                  }

                  &:first-child {
                    &::before {
                      top: ${`calc(${theme.space.default} * -2.75)`};
                    }
                  }
                }

                :global(.blogItemLink:first-child) > li::before {
                  top: ${`calc(${theme.space.default} * -2.75)`};
                }
                h2 {
                  font-size: 2.5em;
                  padding: ${theme.space.s} ${theme.space.s} 0;
                }
                li {
                  &:hover {
                    border: 1px solid ${theme.line.color};
                    box-shadow: 0px 3px 2px rgba(0, 0, 0, 0.03);

                    &:after {
                      bottom: ${`calc(${theme.space.default} * -2.5)`};
                    }
                    :global(.gatsby-image-wrapper) {
                      transform: scale(1.1);
                    }

                    h4 {
                      color: ${theme.blog.h1.hoverColor};
                    }
                  }
                }
              }
            `}</style>
          </Article>
        )}
      </ThemeContext.Consumer>
      <Seo />
    </React.Fragment>
  );
};

ArticlePage.propTypes = {
  data: PropTypes.object.isRequired
};

export default ArticlePage;

//eslint-disable-next-line no-undef
export const query = graphql`
  query ArticlePageQuery {
    posts: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "//posts/[0-9]+.*--/" } }
      sort: { fields: [fields___prefix], order: DESC }
    ) {
      edges {
        node {
          timeToRead
          fields {
            slug
            prefix
          }
          frontmatter {
            title
            category
            intro
          }
        }
      }
    }
  }
`;
